<template lang="pug">
include ../pug/svg
div#course-content(v-if="course")
	SlickList(tag="ol" lockAxis="y" v-model:list="courseContent" useDragHandle=true helperClass="sorting" useWindowAsScrollContainer=true @sort-start="sorting=true" @update:list="setSectionRank($event)" :class="{sorting:sorting}")

		SlickItem.section(v-for="(section, i) in courseContent" :index="i" :key="section.key" tag="li" :id="'sid'+section.id")
			button.but.drag(type="button" v-handle)
				+svg(svg-filename="iconDrag" aria-hidden="true" alt="Reorder icon")
			button.but.bin(type="button" @click="binSection(section)")
				span Delete {{terminology('course','section','singular')}}
				+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
			div.hgroup
				input.txt.h2(type="text" placeholder="Untitled" :value="section.name" @input="setSectionName($event.target.value, section.id)")
				p.h3 {{terminology('course','section','singular')}}

			button.but.circ.prepend(type="button" @click="addLesson(section, true)") Add Content
				+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")

			button.but.circ.append(type="button" @click="addLesson(section, false)") Add Content
				+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
			SlickList(tag="ol" lockAxis="y" v-model:list="section.lessons" useDragHandle=true helperClass="sorting" group="lessons" accept=true useWindowAsScrollContainer=true appendTo="#th" @sort-start="sorting=true" @update:list="setLessonRank($event, section)")
				SlickItem.lesson(v-for="(lesson, z) in section.lessons" :index="z" :key="lesson.key" tag="li")
					button.but.drag(type="button" v-handle)
						+svg(svg-filename="iconDrag" aria-hidden="true" alt="Drag icon")
					div.hgroup
						input.txt.h2(type="text" placeholder="Untitled" :value="lesson.name" @input="setLessonName($event.target.value, lesson.id)")
						input.txt.h3(type="text" placeholder="Untitled" :value="lessonType(lesson.type)" @input="setLessonType($event.target.value, lesson.id)")
					div.butmore
						router-link(:to="{name:'ManageLearningCourseLesson', params:{lessonId:lesson.id}}") Edit Content
						button.but(type="button" aria-haspopup="true" aria-controls="" @mouseenter="toggleTint(true),setMenu($event)" @mouseleave="toggleTint(false)") More
							+svg(svg-filename="iconMore" aria-hidden="true" alt="More icon")
						nav.menu(@mouseenter="toggleTint(true)" @mouseleave="toggleTint(false)")
							div.bar
								+svg(svg-filename="iconMore" aria-hidden="true")
								button.but(type="button") Close
									+svg(svg-filename="iconClose")
							ul
								li
									a(@click="showLessonSettingsModal(lesson)") Edit Content Settings
								li
									a(@click="binLesson(lesson)").bin Delete Content
										+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
					//-table.summary
						tr
							th Content
							td 5 Blocks
						tr
							th Updated
							td Oct 5, 2022 / 3:27pm
						tr
							th Restriction
							td None
	button.but.circ.plus.text.prepend(v-if="courseSections.length" type="button" @click="addSection(true)") Prepend {{terminology('course','section','singular')}}
		+svg(svg-filename="iconPlus" aria-hidden="true" alt="Drag icon")

	button.but.circ.plus.text.append(type="button" @click="addSection(false)") Append {{terminology('course','section','singular')}}
		+svg(svg-filename="iconPlus" aria-hidden="true" alt="Drag icon")

	div.modal.confirm.narrow.center(:class="{active:binModal!==false}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span Delete Section
			button.but(type="button" @click="cancelBin") Close
				+svg(svg-filename="iconClose")
		div.body
			p.small This action will delete all child {{terminology('course','lesson','plural')}}.
		div.foot
			button.but.pri(@click="confirmBin") Continue
			button.but.sec(@click="cancelBin") Cancel

	div.modal.narrow.center(:class="{active:lessonSettingsModal}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span Edit Content Settings
			button.but(type="button" @click="hideLessonSettingsModal") Close
				+svg(svg-filename="iconClose")
		Form.body(ref="formLessonSettings" :validation-schema="lessonSettingsSchema" v-slot="{ errors, isSubmitting, values, meta }" @submit="updateLessonSettings")
			//-pre errors: {{errors}}
			//-pre lessonSettingsModal: {{lessonSettingsModal}}
			transition(name="modalform")
				fieldset.fgroup(v-if="lessonSettingsModal")
					div.field
						Field(name="name" placeholder="Unsupplied" autocomplete="off" :value="lessonSettingsModal.name" :validateOnInput="false")
						label Content Name
						transition(name="fade")
							ErrorMessage.err(name="name" as="span")
					div.field
						Field(name="type" placeholder="Unsupplied" autocomplete="off" :value="lessonType(lessonSettingsModal.type)" :validateOnInput="false" required)
						label Content Type
						transition(name="fade")
							ErrorMessage.err(name="type" as="span")
			div.foot
				span.req Required
				button.but.pri(type="submit" :disabled="!meta.valid||!meta.touched||isSubmitting") Update
				button.but.sec(type="button" @click="hideLessonSettingsModal") Cancel
</template>

<script>
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';

export default {
	name: 'ManageLearningCourseContent',
	props: ['course'],
	emits: ['storeUpdated'],
	directives: { handle: HandleDirective },
	components: {
		SlickList,
		SlickItem,
		Form,
		Field,
		ErrorMessage,
	},
	//mounted() {},
	data() {
		const lessonSettingsSchema = Yup.object().shape({
			name: Yup.string().label('Content Name'),
			type: Yup.string().label('Content Type').required(),
		});

		return {
			lessonSettingsSchema,
			sorting: false,
			binModal: false,
			lessonSettingsModal: false,
		};
	},
	watch: {
		course: {
			handler(course) {
				this.$store.dispatch('gui/setHeader', {
					title: course.name,
					backRoute: {
						text: this.terminology('course','course','plural'),
						name: 'ManageLearning',
					},
				});		
			},
			deep: false,
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		lessonType(type) {
			for (let key in this.$store.state.gui.termMap.course.vars) {
				const singular = this.terminology('course', key, 'singular');
				const re = new RegExp(key, 'gi');
				
				type = type.replace(re, singular); 
			}
			
			return type;			
		},
		setMenu(event) {
			if (event.target.nodeName === 'BUTTON' && event.relatedTarget.nodeName !== 'NAV') {
				const menu = event.target.nextSibling;
				menu.classList.remove('reverse');				
				const rect = menu.getBoundingClientRect();
				
				if (rect.bottom > window.innerHeight) {
					menu.classList.add('reverse');
				}
			}
		},
		storeUpdated() {
			this.$emit('storeUpdated',{valid:true});
		},
		getIndex(list, id) {
			return list.findIndex((e) => e.id === id);
		},
		toggleTint(bool) {
			this.$store.dispatch('gui/setTintState', bool);
		},
		showLessonSettingsModal(lesson) {
			this.lessonSettingsModal = lesson;
			this.toggleTint(true);
		},
		hideLessonSettingsModal() {
			this.lessonSettingsModal = false;
			this.toggleTint(false);
		},
		updateLessonSettings(formData) {
			this.setLessonName(formData.name, this.lessonSettingsModal.id);
			this.setLessonType(formData.type, this.lessonSettingsModal.id);
			this.hideLessonSettingsModal();
		},
		setLessonName(name, lessonId) {
			this.$store.dispatch('courses/setLessonName', {
				courseId: this.course.id,
				lessonId: lessonId,
				name: name,
			});

			this.storeUpdated();
		},
		setLessonType(type, lessonId) {
			this.$store.dispatch('courses/setLessonType', {
				courseId: this.course.id,
				lessonId: lessonId,
				type: type,
			});

			this.storeUpdated();
		},
		setSectionName(name, sectionId) {
			this.$store.dispatch('courses/setSectionName', {
				courseId: this.course.id,
				sectionId: sectionId,
				name: name,
			});

			this.storeUpdated();
		},
		setSectionRank(sections) {
			this.sorting = false;

			for (const [index, section] of sections.entries()) {
				// update section data in courses store
				const data = {
					cId: this.course.id,
					sId: section.id,
					rank: (index + 1),
				};
				
				this.$store.dispatch('courses/setCourseSectionRanks', data);
			}

			this.storeUpdated();
		},
		setLessonRank(lessons, section) { // lessons within dropped section
			this.sorting = false;
			// NEW - update lesson data in courses store
			for (const [index, lesson] of lessons.entries()) {
				const data = {
					cId: this.course.id,
					sId: section.id,
					lId: lesson.id,
					rank: (index + 1),
				};
				
				this.$store.dispatch('courses/setCourseLessonRanks', data);
			}

			this.storeUpdated();
		},
		addSection(bool) {
			this.$store.dispatch('courses/addSection', {
				courseId: this.course.id,
				prepend: bool,
			});

			this.storeUpdated();
		},
		cancelBin() {
			this.binModal = false;
			this.toggleTint(false);
		},
		confirmBin() {
			this.$store.dispatch('courses/binSection', {
				courseId: this.course.id,
				sectionId: this.binModal.id,
			});

			const sectionLessons = this.course.courseLessons.filter(l => l.courseSectionId === this.binModal.id);

			for (const lesson of sectionLessons) {
				this.binLesson(lesson);
			}

			this.storeUpdated();
			this.cancelBin(); // reset
		},
		binSection(section) {
			const sectionLessons = this.course.courseLessons.filter(l => l.courseSectionId === section.id);

			if (sectionLessons.length) {
				// confirmation required
				this.binModal = section;
				this.toggleTint(true);

			} else {
				this.binModal = section;
				this.confirmBin();
			}
		},
		addLesson(section, bool) {
			this.$store.dispatch('courses/addLesson', {
				courseId: this.course.id,
				sectionId: section.id,
				prepend: bool,
			});

			this.storeUpdated();
		},
		binLesson(lesson) {
			this.$store.dispatch('courses/binLesson', {
				courseId: this.course.id,
				lessonId: lesson.id,
			});

			this.storeUpdated();
		},
	},
	computed: {
		courseSections: {
			get() {
				return this.course.courseSections;
			},
			set() {
			}
		},
		courseContent: {
			get() {
				// order course content by rank
				let sections = this.course?.courseSections || [];
				let contents = [];

				if (sections.length) {
					// sections already ordered by rank asc when pulled from db
					for (const section of sections) {
						let sectionData = {
							id: section.id,
							key: 's'+section.id,
							name: section.name,
							rank: section.rank,
							lessons: [],
						};

						// lessons already ordered by rank asc when pulled from db
						let lessons = this.course?.courseLessons || [];
						lessons = lessons.filter(l => l.courseSectionId === section.id);

						for (const lesson of lessons) {
							sectionData.lessons.push({
								id: lesson.id,
								key: 'l'+lesson.id,
								name: lesson.name,
								type: lesson.type,
								rank: lesson.rank,

								//debug
								sId: lesson.courseSectionId,
							});
						}

						// order lessons by rank (NOTE: essential for syncing list order after rank update)
						sectionData.lessons.sort((a, b) => (a.rank > b.rank) ? 1 : ((b.rank > a.rank) ? -1 : 0));

						contents.push(sectionData);
					}
				}

				// order sections by rank (NOTE: essential for syncing list order after rank update)
				contents.sort((a, b) => (a.rank > b.rank) ? 1 : ((b.rank > a.rank) ? -1 : 0));

				return contents;
			},
			set() {
				// handeld by updateSectionRank()
			}
		}
	},
};
</script>

<style lang="scss">
</style>